.tripReplayControls
	// border: 1px solid red
	height: 125px
	// position: absolute
	// bottom: 0
	// left: 0
	// right: 0
	@media (max-width: 991px)
		position: static
	&.disabled
		opacity: 0.3
		.startPlayback,
		.arrow
			cursor: auto !important
	.header
		background-color: var(--theme-colors-primary)
		color: #ffffff
		padding: 5px 0
		text-align: center
		margin-bottom: 5px
	.timeWrapper
		display: flex
		align-items: center
		justify-content: space-around
		text-align: center
		.timeValue
			line-height: 12px
		.currentValueWrapper
			font-size: 16px
			.arrow
				cursor: pointer
			.currentValue
				margin: 0 5px
	// .rc-slider
	// 	height: 20px
	// 	&.rc-slider-disabled
	// 		background: none
	// 		.rc-slider-rail
	// 			background-image: -webkit-linear-gradient(top,#e5e5e5 0,#e9e9e9 100%)
	// 			background-image: -o-linear-gradient(top,#e5e5e5 0,#e9e9e9 100%)
	// 			background-image: linear-gradient(to bottom, #e5e5e5 0, #e9e9e9 100%)
	// 			background-repeat: repeat-x
	// 			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5e5e5',endColorstr='#ffe9e9e9',GradientType=0)
	// 			cursor: not-allowed
	.slider.slider-horizontal
		display: block
		width: 90%
		margin: 0 auto
		.slider-selection
			background-color: var(--theme-colors-tertiary) 
			background-image: none
			border: 1px solid #000
		.slider-handle
			background-color: #000
			background-image: none
			width: 10px
			height: 10px
			top: 5px
		// .rc-slider-rail
		// 	height: 10px
		// 	border-radius: 4px
		// 	cursor: pointer
		// 	background-image: -webkit-linear-gradient(top,#f5f5f5 0,#f9f9f9 100%)
		// 	background-image: -o-linear-gradient(top,#f5f5f5 0,#f9f9f9 100%)
		// 	background-image: linear-gradient(to bottom,#f5f5f5 0,#f9f9f9 100%)
		// 	background-repeat: repeat-x
		// 	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5',endColorstr='#fff9f9f9',GradientType=0)
		// 	-webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%)
		// 	box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%)
		// .rc-slider-track
		// 	height: 10px
		// 	border-radius: 4px
		// 	background-color: var(--theme-colors-tertiary)
		// 	background-image: none
		// 	border: 1px solid #000
		// 	cursor: pointer
		// .rc-slider-step
		// 	height: 10px
		// .rc-slider-handle
		// 	background-color: #000
		// 	background-image: none
		// 	width: 10px
		// 	height: 10px
		// 	top: 10px
		// 	border: none
		// 	cursor: default
		// 	&.rc-slider-handle-dragging
		// 		border: none
		// 		box-shadow: none
	#trip-duration-select
		font-size: 18px
		padding: 2px 0px
		margin: 5px
	.startPlayback
		cursor: pointer
		font-size: 32px