.fade
	padding: 0
.customRequestModal
	font-family: Red Hat Display
	.modal-content
		.modal-header
			padding: 0.5rem 1.5rem
			border-bottom: 2px solid var(--text-color, #333333)
		.btn-close
			color: var(--text-color, #333333)
		.modal-footer
			display: flex
			flex-direction: row
			gap: 10px
			border-top:2px solid #999999
			font-size: 12px
			.rosco-primary-cancel
				width: 147px !important
				height: 30px
				padding: 0
				margin: 0 !important
			.rosco-primary
				width: 147px !important
				height: 30px
				padding: 0
				margin: 0 !important
	.action-title
		font-size: 24px
		font-weight: 400
		line-height: 31.75px
		text-align: left
	.modal-body
		--bs-gutter-x: 0
		--bs-gutter-y: 0
		padding: 12px 0px
		.row
			--bs-gutter-x: 0
			--bs-gutter-y: 0
			padding: 0
			p
				font-size: 12px
				font-weight: 400
				line-height: 15.88px
				text-align: left
				padding: 0px 10px
			.bottom-block
				padding: 10px
				font-size: 12px
				.text-block
					padding-bottom: 40px
					@-moz-document url-prefix()
						li
							margin-left: 10px
			.row-flex
				display: flex
				flex-direction: row
				align-items: flex-start
				height: 50px
				padding: 10px
				gap: 58px
				@media (max-width: 991px)
					flex-direction: column
					gap: 0
					height: auto
				.vehicle-group
					width: 314px
					height: 46px
					padding: 0
					@media (max-width: 768px)
						width: 100%
					.search-autocomplete
						background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='12' height='12' cursor='pointer' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat right
						background-position-y: 25px
						.select-box
							margin-top: -22px
							#states-autocomplete
								width: 100%
								height: 22px
								border-bottom: 2px solid var(--primary, #0071B6)
								padding: 3px 0px
							.control-label
								font-size: 14px
								font-weight: 400
								color: #C0C0C0
								line-height: 18.52px
								text-align: left
								left: 0px
						@media (min-width: 768px) and (max-width: 991px)
							.items-list
								line-height: 30px
						@media (max-width: 768px)
							.items-list
								line-height: 45px
				.available-dates-nav
					display: flex
					margin-bottom: 15px
					.arrows
						margin: 0px 10px
						cursor: pointer
						@media (max-width: 991px)
							display: none
					.event-date
						margin-top: -5px
						.rdt
							position: relative
							width: 10em
							input
								width: 100%
								background-color: #fff
							.highlighted-day
								color: var(--primary, #0071B6)
								font-weight: bold
								&.rdtActive 
									color: #fff
							.calendar-date-legend
								display: flex
								flex-direction: column
								justify-content: space-evenly
								padding: 2px 0 0 10px
								border-top: 1px solid #EFEFEF
								margin: 2px 0
								.legend-option 
									display: flex
									align-items: center
									.legend-icon 
										width: fit-content
										height: fit-content
										text-align: center
										font-weight: bold
										&#req-details 
											color: var(--theme-colors-primary)
										&#no-req 
											color: #999
									.legend-description 
										margin-bottom: 0rem
										margin-bottom: 0rem
		.tab-bar-container
			width: 100%
			height: 46px
			margin-top: 10px
			padding: 10px
			background: var(--Disabled, #C2C2C2)
			.tab-buttons
				display: flex
				flex-direction: row
				justify-content: space-between
				width: 244px
				height: 26px
				padding: 1px
				border-radius: 10px
				background: #FFFFFF
				border: 1px solid #999999
				.toggle
					width: 100px
					height: 22px
					padding: 0
					border-radius: 7px
					font-size: 12px
					font-weight: 500
					line-height: 15.88px
					text-align: center
					color: var(--text-color, #333333)
					background: #FFFFFF
					border: none
					min-width: auto
					&.active
						color: #FFFFFF
						background: var(--primary, #0071B6)
	.request-time-range
		display: flex
		align-items: center
		justify-content: center
		padding: 20px 30px
		gap: 55px
		color: #1E1E1E
		font-weight: 600
		@media (max-width: 991px)
			flex-direction: column
			.text-center
				order: 2
		.start-time, .end-time
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
			width: 175px
			height: 30px
			font-size: 12px
			text-align: center
			padding: 2px 3px
			gap: 7px
			&.has-error
				position: relative
				.flatpickr-input
					padding-bottom: 2px
					border-bottom: 2px solid #f00
				.help-block
					position: absolute
					bottom: -35px
					left: 0
			.flatpickr-input
				border: none
				height: 20px
				width: 110px
				border-bottom: 1px solid var(--primary, #0071B6)
				text-align: center
			label
				display: block
				width: 70px
				font-size: 14px
		.duration-wrapper
			width: 116px
			height: 50px
			text-align: center
			&.has-error
				color: #f00
				.help-block
					position: absolute
					left: 0
					right: 0
			div
				font-size: 14px
				text-align: center
				padding: 0px 2px 3px 0px
			label
				font-size: 10px
				line-height: 10.58px
				text-align: left
			.duration
				display: flex
				flex-direction: row
				padding: 0px 11px
				justify-content: center
				div
					display: flex
					flex-direction: column
					align-items: center
					width: 32px
					height: 28px
					font-size: 14px
				label
					width: 37px
					height: 16px
					padding: 0px 2px
	.details-scenario
		.text
			font-size: 12px
			font-weight: 400
			text-align: left
			margin: 10px 0px 17px 0px
		label
			font-weight: 500
			line-height: 15.88px
			text-align: center
			padding-bottom: 10px
			color: #000000
		.tab-duration-btn
			.hour-btn
				min-width: auto
				width: 70px
				height: 24px
		.tab-hours-btn
			display: flex
			flex-direction: row
			align-items: center
			height: auto
			padding: 4px
			gap: 18px
			border-radius: 4px
			border: 1px solid var(--alt-btn-color, #232323)
			div
				display: flex
				flex-direction: row
				justify-content: center
				align-items: center
				gap: 15px
				li
					span
						margin-left: -5px
						@-moz-document url-prefix()
							margin-left: 0px
			@media (max-width: 991px)
				flex-direction: column
				align-items: flex-start
				div
					margin-left: 33.3%
		.hour-selector-container
			display: flex
			justify-content: center
			width: 100%
			height: 115px
			padding: 25px 30px 20px 30px
			.hour-selector-menu
				display: flex
				flex-wrap: wrap
				flex-direction: column
				justify-content: center
				align-items: center
				width: 674px
				height: 75px
				padding: 3px 11px
				gap: 7px
				border-radius: 4px
				border: 1px solid #000000
				overflow-y: auto
				div
					display: flex
					flex-wrap: wrap
					justify-content: center
					align-items: center
					gap: 3px
				hr
					width: 595px
					height: 2px
					color: #1E1E1E
					margin: 0
			@media (max-width: 991px)
				padding: 25px 0px 20px 0px
				height: auto
				.hour-selector-menu
					height: auto
					div
						height: auto
						width: 300px
					hr
						width: 300px
		.hour-btn
			width: 45px
			height: 24px
			border-radius: 3px
			font-size: 12px
			font-weight: 500
			padding: 0
			&.selected
				background-color: #ed1e79
				border-color: #ed1e79
				color: #fff
			&.no-video
				pointer-events: none
				background-color: #C0C0C0
				border-color: #C0C0C0
				color: #666
		.video-availability-timeline
			margin-top: 20px
			display: flex
			align-items: flex-end
			overflow: auto
			width: 100%
			height: auto
			padding: 9px 8px
			border-radius: 5px
			border: 1px solid #333333
			.timeline-hour
				min-width: 52px
				font-size: 16px
				font-weight: 400
				line-height: 21.17px
				text-align: center
				color: #000000
				padding: 5px
			.timeline-minute
				// width: 25px
				min-width: 25px
				height: auto
				margin-right: 2px
				font-size: 14px
				font-weight: 400
				line-height: 18.52px
				text-align: center
				&.start-video-block
					.bar button
						border-radius: 10px 3px 3px 10px
				&.end-video-block
					.bar button
						border-radius: 3px 10px 10px 3px
				.bar
					height: 25px
					button
						min-width: auto
						border: none
						width: 23px
						height: 22px
.flatpickr-calendar
	z-index: 100010 !important