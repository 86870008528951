.camera-health
	background-color: #fff

.modal-error-status
	ul
		padding-left: 1rem

.camera-health,
.modal-error-status
	.modal-content .modal-footer
		border-top: 1px solid #c0c0c0
	.description-wrapper
		display: grid,
		grid-template-columns: 45% 55%
		// style={{ gridGap: 0, display: 'grid',  gap: 0, gridTemplateColumns: '45% 55%' }}
		.description
			margin: 25px
			&.right-column
				width: 70%
				font-size: 16px
				&.customer
					width: 40%
				.status-definitions-wrapper
					border: 1px solid #C0C0C0
					border-radius: 4px
					position: relative
					.title
						padding: 6px 14px 
						font-weight: bold
						border-bottom: 1px solid #C0C0C0
					.content
						padding: 6px 14px 30px 14px
						.status-definitions
							display: flex	  		
							align-items: center
							justify-content: space-between	
	.with-filter
		line-height: 1
		overflow: visible
		padding-bottom: 0
		padding-top: 0
		.filter-label
			margin-bottom: 0
			overflow: hidden
			text-overflow: ellipsis
			vertical-align: middle
		.filter-dropdown
			display: inline-block
		svg
			height: 1.75rem
			width: 1.75rem
		.dropdown-menu
			color: #333
			font-size: 14px
			padding: 5px
			.groupName
				margin-left: 3px
			.clear-all
				padding: 2px 20px
			.clear-all-container
				margin-top: 8px
				text-align: center
	.custom-table-header-container
		overflow: visible
	.react-bs-table.rosco-table
		border-radius: 5px 5px 0 0
		overflow: visible
	.rosco-table .rosco-table-header th+th
		border-left: 1px solid #fff

	.warn.no-cursor
		background-color: #FB9E14
		border-color: #FB9E14
	.crit.no-cursor
		background-color: #E83219
		border-color: #E83219
	.ackn.no-cursor
		background-color: #666666
		border-color: #666666
	.custom-button.health-status
		.count
			border-radius: 8px
			background: #fff
			color: #e83219
			padding: 1px 4px
			font-size: 0.75rem
			margin-left: 5px
			min-width: 22px
			display: inline-block

	.alerts-list
		border: 1px solid #ccc
		padding: 5px
		height: 120px
		overflow: auto
		button
			border-width: 3px
			&.selected
				border-color: blue

	@media (max-width: 991px)
		.description-wrapper
			display: block
			.description.right-column
				width: auto
