.edit-driver-modal
    padding: 0px 10px
    & > * + *
        margin-top: 10px

.coaching-driver-modal
    text-align: center
    .flex-wrapper
        display: flex
        justify-content: center
        align-items: center
        label
            margin-right: 5px
    .input-wrapper, .toggle-wrapper
        margin-top: 15px
    .toggle-wrapper label
        display: inline-block
        margin-right: 8px
    label
        display: block
        margin-bottom: 0px
    .vehicle-search, .modal-textarea
        @media (min-width: 768px)
            width: 80%
            margin: auto
    .driver-score-label
        font-size: 24px
        margin-top: 15px
    .modal-textarea
        height: 100px
    .modal-image
        width: 80%
    .modal-textarea, .modal-image
        margin-top: 15px
        @media (max-width: 767px)
            width: 100%

.event-weights-modal
    .no-style-list .card
        transition: opacity 0.3s
        .flex-item, .no-style-list
            display: flex
            justify-content: space-between
            align-items: center
        &.disabled
            opacity: 0.6
        .event-weights-label
            flex-basis: 60%
            font-weight: normal
            margin-bottom: 0px
        .react-toggle
            margin-right: 0px
        .slider
            display: block
            width: 90%
            margin: 10px auto 0px
            .slider-selection
                background-color: var(--theme-colors-primary)
                background-image: none
            .slider-handle
                background-color: #00446D
                background-image: none
                cursor: pointer
            .slider-track
                height: 6px
                margin-top: -3px
            .slider-tick
                border-radius: 0px
                width: 4px
                height: 6px
                top: 7px
                margin-left: -2px
                background-image: none
                background-color: var(--theme-colors-primary)
                cursor: pointer
                &.in-selection
                    background-color: #fff
                &:first-child, &:last-child
                    display: none
        .no-style-list
            display: flex
            justify-content: space-between
            width: 90%
            margin: auto
    p
        max-width: 80%
        margin: 0px auto 10px

.add-asset-block .first-two-columns
    button + button
        margin-left: 10px

.three-column-grid .first-two-columns
    grid-column: 1 / 3