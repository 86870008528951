
	
.live-recording-logs
	.table-logs
		position: relative
		margin-bottom: 50px
		.react-bs-table-container
			.rosco-table-header
				display: none
			.table>tbody>tr>td
				padding: 2px
			.react-bs-table-tool-bar 
				position: absolute
				bottom: -45px
				right: 30px
	ul
		margin: 0px auto 15px
		width: 270px
		.statistic-line
			display: flex
			justify-content: space-between
			border-bottom: 1px solid
			margin-bottom: 5px
			line-height: 24px
			span
				font-size: 20px
