.custom-button
  display: inline-block
  line-height: 1.42
  text-align: center
  text-decoration: none
  -webkit-user-select: none
  -moz-user-select: none
  user-select: none
  padding: 6px 12px
  white-space: nowrap
  outline: none
  background-image: none
  border: 1px solid transparent
  border-radius: 4px
  transition: all, 0.3s
  font-size: 0.875rem
  width: auto
  min-width: 185px
  &:focus, &:active, &:active:focus
    box-shadow: none
  &.inverted
    border: 2px solid
    // padding: 0.375rem 0.75rem
    background-color: #fff
    &:hover, &:active, &:focus, &:active:focus
      &:not(:disabled)
        //background-color: #D9D9D9
  &.small
    min-width: 95px
  &.icon
    min-width: unset
    padding: 0.25rem
    & + .icon
      margin-left: 2px
    &.play-button
      border: none
      padding: 0px
      background-color: transparent
      &:hover, &:active, &:focus, &:active:focus
        background-color: transparent
        color: var(--theme-colors-primary)
    &.transparent
      padding: 0.125rem
      &.flagged-button-menu
        color: #333
        &.active
          color: gold
      &.flagged-button
        color: #fff
        &.active, &:hover
          color: gold
    svg
      width: 1.5rem
      height: 1.5rem
  &.health-status
    padding: 3px 8px
    line-height: 1em
    min-width: 75px
    &.warn
      background-color: #FB9E14
      border-color: #FB9E14
    &.crit
      background-color: #E83219
      border-color: #E83219
    &.ackn
      background-color: #666666
      border-color: #666666
  &.empty-button
    padding: 0px
    background-color: transparent
  &.close
    font-size: 21px
    font-weight: 700
    line-height: 1
    opacity: 0.2
    padding: 0px
    background-color: transparent
  &.export-button
    padding: 0.25rem
    display: flex
    justify-content: center
    align-items: center
    svg
      height: 1.25rem
      width: 1.25rem
      margin-right: 5px
  &[disabled], &.disabled
    opacity: 0.6
    cursor: not-allowed
  &.no-cursor
    cursor: inherit
.rosco-primary
  color: #fff
  background-color: var(--theme-colors-primary)
  border: 2px solid var(--theme-colors-primary)
  &:hover, &:active, &:focus, &:active:focus
    color: #fff
    border-color: var(--theme-colors-tertiary)
    background-color: var(--theme-colors-tertiary)
    &[disabled], &.disabled
      background-color: var(--theme-colors-primary)
      border-color: var(--theme-colors-primary)
.rosco-primary.inverted
  color: var(--theme-colors-primary)
  &:hover, &:active, &:focus, &:active:focus
    color: white
    border-color: var(--theme-colors-primary)
    background-color: var(--theme-colors-primary)
.rosco-secondary
  color: #fff
  background-color: var(--theme-colors-secondary) 
  width: auto
  min-width: 95px
  border: 2px solid var(--theme-colors-secondary)
  &:hover, &:active, &:focus, &:active:focus
    border-color: var(--theme-colors-alternate)
    background-color: var(--theme-colors-alternate)
    &[disabled], &.disabled
      background-color: var(--theme-colors-secondary) 
      border-color: var(--theme-colors-secondary) 
  &.save-changes
    width: 150px
    @media (max-width: 991px)
      width: 40%
      display: block
      margin: auto
.rosco-secondary.inverted
  color: var(--theme-colors-secondary)
  &:hover, &:active, &:focus, &:active:focus
    color: white
    background-color: var(--theme-colors-secondary)
    border-color: var(--theme-colors-secondary)
.rosco-tertiary
  color: #fff
  background-color: var(--theme-colors-tertiary)
  &:hover, &:active, &:focus, &:active:focus
    &:not([disabled])
      background-color: var(--theme-colors-primary)
  &.active
    background-color: var(--theme-colors-secondary) !important
    &:hover, &:active, &:focus, &:active:focus
      background-color: var(--theme-colors-primary)
.rosco-tertiary.inverted
  color: var(--theme-colors-tertiary)
.rosco-delete
  color: #fff
  background-color: #f00
  border: 2px solid #ff0000
  &.disabled, &[disabled]
    opacity: 0.5
.rosco-delete.inverted
  color: #f00
  padding: 0px
.transparent
  background-color: transparent
.button-link
  color: var(--theme-colors-tertiary)
  min-width: unset
  padding: 0px
  background-color: transparent
  &.large
    font-size: 20px
    color: var(--theme-colors-primary)
    padding: 0.375rem 0.75rem
    &:hover
      text-decoration: none
      background: #f0f0f0
  &.text
    color: #333
  &:hover, &:active, &:active:focus
    background-color: transparent
    border-color: transparent
  &:hover:not(.no-underline)
    text-decoration: underline
.button-group
  button
    border-radius: 0px
    &:first-child
      border-radius: 4px 0px 0px 4px
    &:last-child
      border-top-right-radius: 4px
      border-bottom-right-radius: 4px
.button-group
  .toggle-button
    min-width: 60px
    background-color: #f0f0f0
    border-color: #f0f0f0f0
    color: #C0C0C0
    &.active, &:hover, &:focus, &:active:focus
      color: #fff
      background: var(--theme-colors-primary)
    &.small
      padding: 0.25rem 0rem
  .toggle-button-group
    min-width: 100px
    &.active, &:hover
      &:not(:disabled)
        background-color: #00446D
#sm
  width: 185px
.flex-btn
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
.custom-disabled
  color: #666
  border: 1px solid #C0C0C0
  background: #C0C0C0
  // &:hover[disabled]
  //   color: #666
  //   border: 1px solid #C0C0C0
  //   background: #C0C0C0
  //   opacity: 0.3