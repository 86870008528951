.AvailableDatesNav
	margin: 10px 0
	text-align: center
	.arrows
		display: inline-block
		text-align: center
		.previous-date, .next-date
			cursor: pointer
			font-size: 18px
		.previous-date
			margin-right: 6px
		.next-date
			margin-left: 6px
	.input
		display: inline-block
