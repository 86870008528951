.custom-checkbox-wrapper
	display: inline-block
	vertical-align: middle
	height: 24px
	input[type=checkbox]
		border: 0
		clip: rect(0 0 0 0)
		clippath: inset(50%)
		height: 1px
		margin: -1px
		overflow: hidden
		padding: 0
		position: absolute
		white-space: nowrap
		width: 1px
	.styled-checkbox
		display: inline-block
		width: 24px
		height: 24px
		background: #fff
		border-radius: 3px
		transition: all 150ms
		border: 1px solid #ccc
		svg
			fill: none
			stroke: white
			stroke-width: 2px
	