.fleet-profiles
    background-color: #fff
    .temp-bar-container
        display: flex
    .temp-bar
        text-align: center
        width: 100%
        color: white
    .hot
        border-radius: 0px 5px 5px 0px
        background: linear-gradient(90deg, #FF8616 0%, #E83219 52.6%)
    .med
        background: linear-gradient(90deg, #0071B4 9.37%, #FF8616 60.94%)
    .cold
        border-radius: 5px 0px 0px 5px
        background: linear-gradient(90deg, #00416D 0%, #0071B4 63.54%)
    .toggle-wrapper
        display: flex
        justify-content: space-between
        align-items: center
        flex-wrap: wrap
        border: 1px solid #C0C0C0
        border-radius: 8px
        padding: 10px
        flex-wrap: wrap
        margin-bottom: 10px
        &.block
            display: block
        &.no-border
            border: none
        .toggle-buttons
            display: inline
            text-align: right
            .toggle-button
                min-width: 60px
                width: min-content
                background-color: #f0f0f0
                border-color: #f0f0f0f0
                color: #C0C0C0
                &.active, &:hover
                    border-color: var(--theme-colors-primary)
                    background: var(--theme-colors-primary)
                    color: #fff
    .panels-wrapper
        display: grid
        grid-template-columns: 55% 45%
        gap: 0
        .asset-description
            margin: 0
        .add-asset-block
            margin-bottom: 20px
        .setting-profiles-panel
            border-right: 1px solid #ccc
        .profile-preview-panel
            &.no-selected-profile
                display: flex
                align-items: center
                justify-content: center
                max-height: calc(100vh - 100px)
                font-size: 20px
                color: #ccc
            .panel-header
                display: flex
                align-items: center
                justify-content: space-between
                .name-wrapper
                    display: flex
                    align-items: center
                    .profile-name
                        margin-right: 15px
                        font-size: 22px
                        word-break: break-all
                        &.has-error
                            color: #f00
                .error-message
                    color: #f00
            .category-title
                font-size: 20px
            .info-category
                .info-title
                    border-bottom: 1px solid #000
                    margin-bottom: 10px
            .comments
                textarea
                    width: 100%
                    height: 80px
                    resize: vertical
                    outline-width: 0
                    &.editable
                        border-color: #29A9E0
            .depreciated
                font-weight: bold
                font-size: 14px
                color: #bbb
                margin-left: 5px
                       
    .fleet-profiles-message
        margin-top: 20px
        font-size: 20px
        .btn-link
            font-size: 20px
        span
            vertical-align: middle
    .actions-dropdown.show
        .dropdown-toggle::after
            transform: rotate(180deg)
        .dropdown-menu
            .dropdown-divider
                margin: 0
            .dropdown-item
                color: #29a9e0    
                text-align: left
                &.delete
                    color: red
    .backlight-slider
        flex-grow: 1
        position: relative
        // top: 3px
        .slider
            position: relative
            margin-right: 5px
            // bottom: 10px
        .slider-minmax-value
            position: absolute
            top: 10px
            font-size: 12px
            &.min-value
                left: 0
            &.max-value
                right: 55px



    .page-subheader
        display: flex
        justify-content: space-between
        align-items: center
        .profile-name
            font-size: 20px
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
        .config-dropdown
            display: inline-block
            .edit-profile
                margin-left: 10px
                min-width: unset
                padding: 4px 5px
                svg
                    vertical-align: middle
            .rosco-select
                vertical-align: bottom
                padding: 6px 4px 4px 0px
            @media (max-width: 991px)
                display: flex
                justify-content: center
                margin-left: 0px
                .rosco-select
                    max-width: 400px
                    flex-basis: 100%
    .settings-page
        width: 60%
        .category-subheader
            margin-bottom: 20px
    .settings-page.disabled .cards-wrapper .card-body
        .toggle-wrapper .toggle-buttons
            opacity: 0.5
            cursor: not-allowed
            button, input
                pointer-events: none
        .rosco-select, .react-numeric-input, .number-input
            opacity: 0.5
            pointer-events: none
        .slider-wrapper .backlight-slider, .slider-box
            opacity: 0.5
            cursor: not-allowed
            .slider, svg
                pointer-events: none
        .channel-options-wrapper .channel-options .button-group
            opacity: 0.5
            cursor: not-allowed
            button
                pointer-events: none
        .box-toggle
            cursor: not-allowed
            .react-toggle
                opacity: 0.5                
                pointer-events: none
    .cards-wrapper
        display: grid
        margin-top: 10px
    .general-settings .cards-wrapper
        grid-template-columns: 2fr 2fr 3fr
        gap: 1em
        &.one-column
            grid-template-columns: 1fr
            .dv6-general
                grid-column-end: 2
    .channel-settings .cards-wrapper
        grid-template-columns: 2fr 1fr
        gap: 2em 3em
        &.one-column
            grid-template-columns: 1fr
            gap: 1em
            .recording-stats
                grid-column-start: 1
                grid-column-end: 2
                grid-row-start: 3
        .recording-stats
            grid-column-start: 2
            grid-row-start: 1
            grid-row-end: 3
            .settings-box
                margin-top: 10px
    .event-settings .cards-wrapper
        grid-template-columns: 1fr 1fr
        gap: 1em
        &.one-column
            grid-template-columns: 1fr
            .dv6-events
                grid-column-start: 1
                grid-column-end: 2
                .card-body
                    display: block
        .dv6-events 
            grid-column-start: 1
            grid-column-end: 3
            .card-body
                display: grid
                gap: 0 1em
                grid-template-columns: 1fr 1fr
                .dv6-dms-box
                    grid-row-start: 1
                    grid-row-end: 3
    .settings-card
        margin-bottom: 20px
        .card-header
            display: flex
            justify-content: space-between
            align-items: center
            font-size: 18px
            border-bottom: 1px solid #1E1E1E
            padding: 10px
            background-color: #fff
            font-weight: bold
            &.dv6-card-header
                border-color: var(--theme-colors-alternate)
            &.dv4-card-header
                border-color: var(--theme-colors-tertiary) 
            .subtitle
                font-weight: 100
                margin-left: 5px
                font-style: italic
            .sublabel
                font-weight: normal
                font-size: small
                padding: 5px
                margin-left: 17px
                background: #f0f0f0
                border-radius: 5px
        .card-body
            padding: 8px 0
            animation: fade-in 0.5s
            .settings-box
                border: 1px solid #ccc
                border-radius: 5px
                padding: 8px
                margin-top: 10px
                &.disabled
                    .toggle-wrapper .toggle-buttons
                        opacity: 0.5
                        cursor: not-allowed
                        button
                            pointer-events: none
                    .rosco-select, .react-numeric-input
                        opacity: 0.5
                        pointer-events: none
                .box-header
                    margin-bottom: 10px
                    font-family: var(--theme-font)
                    font-size: 16px
                    &.no-margin
                        margin: 0
                    .box-subheader
                        font-size: 13px
                        font-style: italic
                        color: #888                    
                    a
                        font-weight: 300
                        margin-left: 5px
                hr
                    margin: 10px 0px
            .dropdown-wrapper, .channel-options
                border: 1px solid #C0C0C0
                border-radius: 8px
                padding: 10px
                display: flex
                justify-content: space-between
                align-items: center
                flex-wrap: wrap
                margin-bottom: 10px
            .toggle-wrapper, .dropdown-wrapper, .channel-options
                &.disabled
                    .toggle-buttons
                        opacity: 0.5
                        cursor: not-allowed
                        button
                            pointer-events: none
                    .rosco-select, .react-numeric-input
                        opacity: 0.5
                        pointer-events: none
                &.align-bottom
                    align-items: end
                .settings-title
                    font-size: 16px
                    font-style: normal
                    font-weight: 400
                    line-height: normal
                    margin-bottom: 0px
                    max-width: 70%
                .sublabel
                    font-weight: 400
                    font-size: small
                    padding: 5px
                    margin: 0px auto 0px 17px
                    background: #f0f0f0
                    border-radius: 5px
                    &.top
                        display: block
                .toggle-buttons
                    display: inline
                    text-align: right
                .empty-box
                    width: 173.5px
                    &.firefox
                        width: 172px
                .dms-title
                    flex-basis: 195px
                .adas-title-dv6
                    flex-basis: 210px
                .adas-title
                    flex-basis: 225px
                .event-title
                    flex-basis: 120px
                .harsh-event-title
                    flex-basis: 180px
                    .beta-label
                        font-size: 13px
                        font-style: italic
                        color: #888
                .rosco-select
                    width: 195px
                    &.mini
                        width: 160px
                        min-width: 160px
                .react-numeric-input
                    display: inline-block !important
                .inline
                    display: inline
                .minimum-speed
                    width: 150px
                .mr5
                    margin-right: 5px
                .mr10
                    margin-right: 10px
            .toggle-wrapper:last-child
                margin-bottom: 0px
            .slider-wrapper
                // display: flex
                // justify-content: space-between
                // align-items: center
                &.align-top
                    align-items: flex-start
                label, span
                    flex-basis: 90px
                    position: relative
                    font-size: 16px
                    top: 2px
                    &.chime-label
                        flex-basis: 110px
                .backlight-slider
                    flex-grow: 1
                    position: relative
                    // top: 3px
                    .slider
                        position: relative
                        margin-right: 5px
                        // bottom: 10px
                    .slider-minmax-value
                        position: absolute
                        top: 10px
                        font-size: 12px
                        &.min-value
                            left: 0
                        &.max-value
                            right: 55px
                .minimum-speed
                    width: 150px
                // @media (min-width: 820px)
                //     .button-group:nth-child(2)
                //         margin-right: 4px
            .button-group
                button
                    outline: none !important
                .toggle-button
                    min-width: 60px
                    width: min-content
                    background-color: #f0f0f0
                    border-color: #f0f0f0f0
                    color: #C0C0C0
                    &.active, &:hover
                        border-color: var(--theme-colors-primary)
                        background: var(--theme-colors-primary)
                        color: #fff
                .toggle-button-group
                    min-width: 100px
                    width: min-content
                    &.active, &:hover
                        background-color: #00446D
                        color: #fff
                        border: 2px solid #00446D
                &.CAN-settings
                    display: flex
                    flex-wrap: wrap
                    max-width: 50em
                    margin: auto
                    button
                        flex-grow: 1
                        min-width: min-content
                        width: 25%
                    @media (max-width: 767px)
                        button
                            width: 50%
                            &:first-child
                                border-bottom-left-radius: 0px
                            &:nth-child(2)
                                border-top-right-radius: 5px
                            &:nth-child(3)
                                border-bottom-left-radius: 5px
                            &:last-child
                                border-top-right-radius: 0px
            .center-buttons
                text-align: center
                margin-bottom: 10px
            .stats-container
                display: flex
                // justify-content: space-evenly
                justify-content: space-between
                width: 72%
                .stat-wrapper
                    // margin: 10px 0px
                    // text-align: center
                    // flex-basis: 25%
                    text-align: center
                    border: 1px solid #c0c0c0
                    border-radius: 4px
                    width: 22%
                    padding: 19px 0
                    .file-size
                        margin-bottom: 18px
                        font-size: 16px
                    .recording-hours
                        font-size: 24px
            .slider-box
                width: 80%
                margin: auto
                .slider.slider-horizontal
                    width: 100%
                .vehicle-grid
                    display: flex
                    justify-content: space-between
                    align-items: center
                    margin: 0px -10px
                    .sensitivity-icon
                        cursor: pointer
                        &:hover, &.active
                            color: var(--theme-colors-primary)
                        &.car-icon
                            transform: rotateY(180deg)
                .sensitivity-label
                    display: flex
                    justify-content: space-between
                    margin: 0px -10px
                    label
                        margin: 0px
                        color: #888
            .item-dv6
                display: flex
                flex-direction: row
                padding: 20px 0px 10px 0px
                width: 95%
                .box-toggle
                    display: flex
                    flex-direction: row
                    align-items: center
                .react-toggle
                    margin-left: 8px
                .slider
                    margin: 0px 25px 0px 25px
                .slider.slider-horizontal
                    width: 30%
            @media (max-width: 620px)
                .item-dv6
                    flex-direction: column
                    .slider
                        margin: 25px 0px 25px 0px
                    .slider.slider-horizontal
                        width: 100%
            .speeding-description
                padding: 0px 25px
                h5
                    font-size: 20px
                    font-weight: normal
                p
                    margin-bottom: 0px
                ul
                    margin: 10px 0px
                    padding-left: 25px
                    list-style-type: square
                p, ul
                    font-size: 16px
                    font-weight: 300
        .value-label
            margin-top: 10px
            .vehicle-search
                width: 125px
                text-align: center
                margin-left: 5px
        .advanced-settings-tab
            display: flex
            justify-content: space-around
            align-items: center
            flex-wrap: wrap
            margin-bottom: 10px
            font-size: 16px
            .device-label
                flex-basis: 100px
            .rosco-select
                margin-left: 5px
                min-width: 100px
    .advanced-settings-toggle
        margin-top: 15px
    .list-wrapper
        .profile-item
            // display: grid
            // grid-template-columns: 2fr 4fr 4fr 2fr 1fr
            // gap: 10px
            // font-size: 14px
            // color: #666666
            // cursor: auto
            display: flex
            align-items: center
            justify-content: space-between
            position: relative
            cursor: pointer
            box-shadow: none
            color: #0071b6
            &:hover, &.active
                color: #29A9E0
                border-color: #29A9E0
                box-shadow: 0px 1px 5px 0px #D9D9D9
            .bi-arrow-right
                margin: 0 5px
            //     position: absolute
            //     right: 10px
            //     top: 50%
            //     transform: translateY(-50%)
            .name
                // max-width: 80%
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap
    @media ( max-width: 1400px)
        .settings-page
            width: 80%
        .settings-card .card-body .stats-container
            width: 90%
        .channel-settings .cards-wrapper
            grid-template-columns: 1fr
            gap: 1em
            .recording-stats
                grid-column-start: 1
                grid-column-end: 2
                grid-row-start: 3  
    @media (max-width: 1310px)
        .general-settings .cards-wrapper
            grid-template-columns: 1fr 1fr
            .dv6-general
                grid-column-start: 1
                grid-column-end: 3   
    @media (max-width: 1260px)
        .settings-page
            width: 100%
        .settings-card .card-body .stats-container
            width: 100%
        .event-settings .cards-wrapper
            grid-template-columns: 1fr
            .dv6-events
                grid-column-start: 1
                grid-column-end: 2
                .card-body
                    display: block       
    @media (max-width: 800px)
        .general-settings .cards-wrapper
            grid-template-columns: 1fr
            .dv6-general
                grid-column-end: 2
        .panels-wrapper
            display: block
    @media (max-width: 767px)
        .channel-options .button-group
            margin: 5px 0px
        .settings-card .card-body .toggle-wrapper
            .event-title, .dms-title, .adas-title-dv6, .harsh-event-title
                flex-basis: 100%
                margin-bottom: 5px
        .dms-sensitivity .toggle-buttons
            margin: auto
        .settings-card .advanced-settings-tab 
            .device-label
                flex-basis: initial
            .advanced-wrapper
                flex-basis: 100%
                text-align: center
    
    .form-group,
    .form-block
        margin-bottom: 10px
    .header-slider
        width: 50%
        position: relative
        height: 35px
        .slider-minmax-value
            position: absolute
            top: 23px
            font-size: 12px
            font-weight: normal
            &.min-value
                left: 0
            &.max-value
                right: 0
    .slider-wrapper, .slider-box
        .rangeslider.rangeslider-horizontal
            height: 6px
            border-radius: 6px
            margin: 0
            background-color: #e9e9e9
            .rangeslider__fill
                background-color: var(--theme-colors-primary)
                border-radius: 6px
            .rangeslider__handle
                width: 20px
                height: 20px
                border-radius: 50%
                background-color: #00446d
                border: none
                box-shadow: none
                &:after
                    display: none
        .rc-slider.rc-slider-horizontal
            cursor: pointer
            .rc-slider-rail
                background-color: f5f5f5
                height: 6px
                box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1)
            .rc-slider-track
                background-color: var(--theme-colors-primary)
                background-image: none
                height: 6px
            .rc-slider-handle
                height: 20px
                width: 20px
                margin-left: 0
                margin-top: -8px
                background-color: #00446D
                background-image: none
                border: none
                opacity: 1	
                &.rc-slider-handle-dragging
                    box-shadow: none
        .slider.slider-horizontal
            // width: calc(100% - 55px)
            width: 100%
            .slider-track
                height: 6px
                .slider-selection
                    background-color: var(--theme-colors-primary)
                    background-image: none
                .slider-track-high
                    background-color: #e9e9e9
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px inset
            .slider-handle
                // background-color: #00446D
                // background-image: none
                margin-top: -2px
            .tooltip
                position: absolute
                opacity: 0
                left: 100px
                transform: none
            .tooltip.show
                opacity: 0.9
            .tooltip .arrow
                position: absolute
                display: block
                width: 0.8rem
                height: 0.4rem
            .tooltip .arrow::before
                position: absolute
                content: ""
                border-color: transparent
                border-style: solid
            .bs-tooltip-top,
            .bs-tooltip-auto[x-placement^="top"]
                padding: 0.4rem 0
            .bs-tooltip-top .arrow,
            .bs-tooltip-auto[x-placement^="top"] .arrow
                bottom: 0
            .bs-tooltip-top .arrow::before,
            .bs-tooltip-auto[x-placement^="top"] .arrow::before
                top: 0
                border-width: 0.4rem 0.4rem 0
                border-top-color: #000
            .bs-tooltip-right,
            .bs-tooltip-auto[x-placement^="right"]
                padding: 0 0.4rem
            .bs-tooltip-right .arrow,
            .bs-tooltip-auto[x-placement^="right"] .arrow
                left: 0
                width: 0.4rem
                height: 0.8rem
            .bs-tooltip-right .arrow::before,
            .bs-tooltip-auto[x-placement^="right"] .arrow::before
                right: 0
                border-width: 0.4rem 0.4rem 0.4rem 0
                border-right-color: #000
            .bs-tooltip-bottom,
            .bs-tooltip-auto[x-placement^="bottom"]
                padding: 0.4rem 0
            .bs-tooltip-bottom .arrow,
            .bs-tooltip-auto[x-placement^="bottom"] .arrow
                top: 0
            .bs-tooltip-bottom .arrow::before,
            .bs-tooltip-auto[x-placement^="bottom"] .arrow::before
                bottom: 0
                border-width: 0 0.4rem 0.4rem
                border-bottom-color: #000
            .bs-tooltip-left,
            .bs-tooltip-auto[x-placement^="left"]
                padding: 0 0.4rem
            .bs-tooltip-left .arrow,
            .bs-tooltip-auto[x-placement^="left"] .arrow
                right: 0
                width: 0.4rem
                height: 0.8rem
            .bs-tooltip-left .arrow::before,
            .bs-tooltip-auto[x-placement^="left"] .arrow::before
                left: 0
                border-width: 0.4rem 0 0.4rem 0.4rem
                border-left-color: #000
            
                // cursor: pointer
                // top: -5px
                // -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgb(39 39 39 / 20%)
                // box-shadow: 0 0.125rem 0.25rem 0 rgb(39 39 39 / 20%)
            // .slider-track
            //     height: 6px
            //     margin-top: -3px
            // .slider-tick
            //     border-radius: 0px
            //     width: 4px
            //     height: 6px
            //     top: 7px
            //     margin-left: -2px
            //     background-image: none
            //     background-color: var(--theme-colors-primary)
            //     cursor: pointer
            //     &.in-selection
            //         background-color: #fff
            //     &:first-child, &:last-child
            //         display: none
            // .slider-tick-label-container
            //     .slider-tick-label
            //         &:first-child
            //             left: 0 !important
            //             width: auto !important
            //             margin: 0 !important
            //         &:nth-child(2)
            //             right: 0 !important
            //             left: auto !important
            //             width: auto !important
            //             margin: 0 !important
        .slider-value
            display: inline-block
            width: 50px
            text-align: center
    @media (min-width: 991px)
        .page-subheader
            .subheader-section
                .actions-dropdown
                    margin-top: 1px

.add-profile-modal
    select
        max-width: 100%

.calibration-needed
    .toggle-wrapper
        display: flex
        justify-content: space-between
        align-items: center
    .items-list
        width: 80%
        margin: auto
        max-height: 200px
        // overflow: auto
        label
            font-weight: 400
    .glyphicon-alert
        color: #F28131
        font-size: 17px
        margin-right: 3px
    .glyphicon-ok
        color: #6ACA25
        font-size: 20px
.adas-calibration-modal
    .adas-calibration
        .input-wrapper
            .settings-title
                width: 140px
        label
            font-weight: 500
        // .number-input
        //     width: 60px

.config-modal-body
    width: 80%
    margin: auto
    min-height: 150px
    &.auto-height
        min-height: auto
    label
        font-size: 16px
        font-weight: normal
        word-break: break-all
    .profile-vehicle-list
        max-height: 275px
        overflow: auto
    .profile-action
        text-align: center
        margin: 5px 0px 10px 
        button
            width: auto
    .message
        font-size: 16px
        margin-bottom: 10px
    .btn-link
        padding: 0px 0px 1px
        font-size: 16px
        display: inline
    .available-vehicle-list
        margin-left: 15px
    @media (max-width: 767px)
        .rosco-select
            width: 100%

.error-message
    color: #ff0000
    margin-bottom: 10px
    font-size: 16px

// from /css/bootstrap.min.3.3.7.css
// html
//     font-size: 10px
body
    // font-family: "Helvetica Neue",Helvetica,Arial,sans-serif
    font-size: 14px
    line-height: 1.42857143
    // color: #333
    // background-color: #fff
    // display: inline-block
    // padding: 6px 12px
    // margin-bottom: 0
    // font-size: 14px
    // font-weight: 400
    // line-height: 1.42857143
    // text-align: center
    // white-space: nowrap
    // vertical-align: middle
    // -ms-touch-action: manipulation
    // touch-action: manipulation
    // cursor: pointer
    // -webkit-user-select: none
    // -moz-user-select: none
    // -ms-user-select: none
    // user-select: none
    // background-image: none
    // border: 1px solid transparent
    // border-radius: 4px
// label
    // display: inline-block
    // max-width: 100%
    // margin-bottom: 5px
    // font-weight: 700

@keyframes fade-in
    0%
        opacity: 0
    100%
        opacity: 1