.reports-container
    .subheader-section
        label
            flex: 200px 0 1
            align-self: center
            margin-right: 10px
            margin-bottom: 0px
        .add-report
            width: auto
            margin-left: 10px
    .reports-body
        .reports-category
            display: inline-block
            vertical-align: top
            margin-bottom: 20px
            margin-top: 0px
            .category-list
                margin-top: 10px
            .add-report
                margin: 25px 0px 0px 5px
                .add-report-button
                    border: none
                    color: var(--theme-colors-primary)
                    font-size: 20px
            .no-reports-message
                margin: 25px 0px 0px 15px
                font-size: 20px
    .report
        .page-subheader
            .force-date
                margin-left: 10px
        .secondary-table-container
            margin-top: 30px
        .table-header
            margin-bottom: 7px
            .report-title
                font-size: 28px
            .autocomplete-wrapper
                margin-left: 0.3rem
                .items-list
                  max-width: 500px
        .react-bs-table-tool-bar
            position: absolute
            right: 0px
            top: -38px
        .react-bs-table-container
            position: relative
            &:not(.event-breakdown) .react-bs-table
                .react-bs-container-header, .react-bs-container-body
                    min-width: 700px
        .report-date-time
            margin-left: 20px
            input
                background-color: var(--theme-colors-primary)
                border: 2px solid var(--theme-colors-primary)
                color: #fff
        .company-name-wrapper
            display: flex
            align-items: center
            margin-top: 3px
        .company-checkbox
            height: 20px
            margin-right: 5px
            width: 20px
            &.column-header
                transform: translateY(4px)
        .jumbotron-custom
            font-size: 18px
    @media ( min-width: 992px )
        .reports-category
            width: 33.33%
            & + .reports-category:not(:nth-child(4))
                padding-left: 25px
        .reports-body.two-columns
            .reports-category
                width: 50%
                max-width: 700px
                & + .reports-category
                    padding-left: 25px
    @media ( max-width: 991px ) and ( min-width: 768px )
        .reports-header
            .subheader-section.search
                margin: 0px
                width: 100%
                &.add-report
                    width: calc(98% - 116px)
                input
                    margin-top: 0px
            .add-report 
                margin-left: 2%
        .reports-body
            .reports-category
                width: 50%
                &:nth-child(even)
                    padding-left: 25px
                &:nth-child(odd)
                    padding-left: 0px
    @media ( max-width: 767px )
        .reports-body
            &.two-columns .reports-category, .reports-category
                width: 100%
                padding: 0px
        .report .report-title
            margin-bottom: 45px
            text-align: center

//MODALS
.reports-modal, .notifications-modal, .config-modal
    .react-bs-table
        .rosco-table-header, .rosco-table-body
            min-width: 700px
    .modal-body
        .modal-subtitle
            text-align: left
            font-size: 16px
            margin: 0px auto 10px
        .modal-search
            width: 50%
            margin: 0px auto 15px
        .share-report-body
            text-align: center
            .user-select-box-wrapper
                @media (min-width: 768px)
                    display: flex
                    .select-box:first-child
                        margin-right: 15px
            .date-select .rosco-primary
                min-width: 40px
                padding: 4px 0px
                margin-top: 10px
                &.active
                    border-color: #00446D
                    background-color: #00446D
                    color: #fff
            .rosco-select
                display: block
                margin-top: 6px
            .modal-grid
                display: grid
                grid-template-columns: 1fr 1fr
                gap: 1em
                margin-bottom: 3em
                border: 1px solid #ccc
                border-radius: 8px
                padding: 5px 8px 8px
                .grid-item
                    text-align: left
                    &.day-select
                        text-align: center
                        grid-column-start: 1
                        grid-column-end: 3
                    .email-wrapper
                        display: inline-flex
                        margin: 3px 0px
                        .email, .remove
                            border: 1px solid var(--theme-colors-primary)
                            color: var(--theme-colors-primary)
                            background: #fff
                        .email
                            padding: 4px 2px
                            border-right: none
                            border-radius: 5px 0px 0px 5px
                        .remove
                            border-radius: 0px 5px 5px 0px
                            padding: 2px 3px
                            &:hover
                                background: #f0f0f0
                @media (max-width: 767px)
                    grid-template-columns: 1fr
                    .grid-item
                        text-align: center
                        .rosco-select
                            margin: 6px auto 0px
                        &.day-select
                            grid-column-end: 2
            .subscription-wrapper
                margin-bottom: 15px
                border: 1px solid #ccc
                border-radius: 8px
                padding: 5px
                .subscription-details
                    margin-bottom: 15px
                    font-size: 16px
                    text-align: left
            .add-subscription-wrapper
                text-align: left
                .add-subscription
                    width: auto
                    margin-top: 35px
        .subscriptions-body .button-link + .button-link
            margin-left: 5px
        .fields-select
            .report-info
                margin-bottom: 10px
            .fields-category
                .category-fields
                    margin: 5px auto 10px
                    .field-container
                        display: inline-block
                        input[type=checkbox]
                            height: 20px
                            width: 20px
                        .field
                            margin-left: 5px
                            margin-right: 10px
                            font-size: 15px
                            position: relative
                            bottom: 5px
        .report-input
            width: 40%
        .date-label
            width: 100px
            font-size: 20px
        .rdt
            width: 200px
            display: inline-block
            margin-left: 5px
            margin-top: 10px
    @media ( min-width: 768px )
        &.table-modal .modal-dialog
            width: 80%
        .modal-body .modal-column
            width: 75%
        .modal-body .two-columns 
            display: flex
            justify-content: space-between
            .modal-column
                width: 49%
    @media ( max-width: 767px )
        .modal-body .report-input
            width: 100%
    .error-msg
        color: #ff0000
        font-size: 16px

//CHARTS TAB
.reports
    overflow: hidden
    background-color: #f6f6f6
    padding-top: 9px
    margin-top: 20px
    @media (min-width: 992px)
        display: flex
    .reports-block
        width: 250px
        flex-shrink: 0
        background-color: #f6f6f6
        padding-bottom: 15px
        @media (max-width: 991px)
            width: 100%
        .reports-list-container
            padding: 5px 15px
            .chart-buttons
                margin-bottom: 10px
                button
                    width: 100%
                    & + button
                        margin-top: 5px
                    svg
                        margin-right: 3px
            .report-item
                color: var(--theme-colors-primary)
                min-height: 60px
                padding: 4px
                overflow: hidden
                box-shadow: 1px 1px 2px rgba(0,0,0,0.5)
                margin-bottom: 7px
                background-color: #FFFFFF
                word-wrap: break-word
                border-left: 10px solid var(--theme-colors-primary)
                &.off
                    border-left: 10px solid #D9534F
                .report-name
                    width: 100%
                    padding-top: 5px
                .report-control
                    width: 100%
                    border-bottom: 1px solid #e0e0e0
                    text-align: right
                    height: 24px
                    .react-toggle-track
                        width: 41px
                        height: 16px
                    .custom-toggle.react-toggle--checked .react-toggle-track
                        background-color: var(--theme-colors-primary)
                    .react-toggle-thumb
                        width: 14px
                        height: 14px
                        border-color: var(--theme-colors-primary)
                    i
                        margin-left: 5px
                        margin-right: 5px
                        color: var(--theme-colors-primary)
                        font-size: 16px
                        vertical-align: middle
                        &:hover
                            transform: scale(1.5)
                    .fa
                        cursor: pointer
                        &:hover
                            color: #000

    .charts-block
        min-height: 773px
        flex-basis: 100%
        background-color: #f6f6f6
        .charts-message
            font-weight: normal
            text-align: center
        .chart-canv
            padding: 0 5px 0 5px
            box-shadow: 0 1px 13px rgba(0,0,0,0.20), 0 1px 7px rgba(0,0,0,0.20)
            cursor: move
            background-color: #FFFFFF
            h3
                font-size: 20px
                margin: 5px 0 0 0
                white-space: nowrap
                overflow: hidden
                border-bottom: 1px solid rgb(224, 224, 224)
                color: var(--theme-colors-primary)
                &.has-error
                    color: #f00
            &:hover
                box-shadow: 0 4px 23px rgba(0,0,0,0.22), 0 4px 20px rgba(0,0,0,0.24)
            

.edit-report-chart
    .form-group
        label
            display: inline-block
            max-width: 100%
            margin-bottom: 5px
            font-weight: 700
    @media (min-width: 768px)
        padding: 0px 30px

body.mobile .charts-block .react-grid-layout
    height: 100% !important
    .react-grid-item
        min-height: 400px
        margin-bottom: 15px
        position: initial !important
        transform: translate(10px, 0) !important