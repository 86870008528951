.vehicle-sidebar
    height: calc(100vh - 67px)
    width: 0px
    top: 0
    bottom: 0
    // position: absolute
    right: 0
    .wrapperSidebar .vehicleSidebarToggle
            z-index: 1
            outline: none
            height: 75px
            width: 35px
            right: 0
            top: calc(50% - 35px)
            position: absolute
            box-shadow: -2px 2px 3px 0 rgba(0,0,0,0.2)
            border: 1px solid #888
            border-right-width: 0
            border-top-left-radius: 10px
            border-bottom-left-radius: 10px
            padding: 0px
            background-color: #fff
            color: var(--theme-colors-primary)
            svg
                vertical-align: middle
                height: 4.5em
                @media (max-width: 991px)
                    height: 2em
            &:hover:not(.disabled)
                background-color: var(--theme-colors-primary)
                border-color: var(--theme-colors-primary)
                color: #fff
            &.disabled
                background-color: #C7C7C7
                color: #888
    .sidebarContent
        display: block
        height: 100%
        border-left: 1px solid #888
        overflow-y: auto
        overflow-x: hidden
        @media (max-width: 991px)
            border: 1px solid #888
        .headerWrapper
            .header
                padding: 6px
                display: flex
                justify-content: space-between
                align-items: center
                background: #F0F0F0
                border-bottom: 1px solid #000
                .vehicle-name
                    font-size: 16px
                    font-weight: 400
                    margin-right: 5px
                    vertical-align: middle
                .vehicle-group
                    font-size: 12px
                    font-weight: 700
            .vehicle-data
                padding: 6px 10px
                border-bottom: 1px solid #C0C0C0
                .status-row
                    margin-bottom: 3px
                    .rssi-signal
                        width: 20px
                        height: auto
                        img
                            width: 100%
            .actionsTitle
                color: #fff
                padding-top: 15px
                font-weight: 600
                text-align: center
            .navigation
                display: flex
                justify-content: space-around
                padding: 5px 0
                border-bottom: 2px solid #000
                .menuItem                    
                    // text-align: center
                    // background-size: 50px
                    // width: 100%
                    // height: 35px
                    // cursor: pointer
                    // color: var(--theme-colors-primary)
                    width: 44px
                    height: 38px
                    &.disabled
                        color: #888
                        svg
                            fill: #888
                    .stop-route-history-title
                        color: #ff0000
                        font-size: 10px
                        position: absolute
                .stopTitle,.betaTitle
                    color: #ff0000
                    text-align: center
                    position: relative
                    bottom: 5px
                    padding-top: 3px
                    font-size: 14px
                .betaTitle
                    color: #fff
                    font-weight: 500
        .vehicle-events-wrapper
            .vehicle-events-view
                display: flex
                justify-content: space-between
                align-items: center
                padding: 6px 10px
                border-bottom: 1px solid #C0C0C0
                margin-bottom: 5px
                .order-filter
                    display: flex
                    align-items: center
                .view-format-select
                    .custom-button
                        margin: 0
                        &:hover, &.active
                            background-color: var(--theme-colors-primary)
                            border-color: var(--theme-colors-primary)
                            color: #fff
                        &:first-child
                            border-radius: 5px 0px 0px 5px
                        &:last-child
                            border-radius: 0px 5px 5px 0px
                        &.icon svg
                            width: 18px
                            height: 18px
.showVehicleSidebar
    #map-block
        left: 700px
    .vehicle-sidebar
        width: 400px
        float: left
        background-color: #fff
        z-index: 2
        border-right: 2px solid #ccc
        .vehicleSidebarToggle
            right: 700px
        // .sidebarContent
        //     display: block

#map-block
    top: 0px
    // left: 300px
    left: 0
    right: 0px
    bottom: 0px
    visibility: visible
    position: absolute
    margin-left: 0px

.infoTripTime
    margin-top: -5px
    .H_ib_body
        transform: translate(50%, 0%)
        margin-right: 0
        .H_ib_close
            display: none

@media (max-width: 991px)
    .vehicle-sidebar
        width: 100% !important
        position: initial !important
        height: auto
        margin-bottom: 15px
        .wrapperSidebar
            // display: none
            .vehicleSidebarToggle
                width: 100%
                height: 40px
                position: initial
                border-radius: 0
                border-right-width: 1px
                box-shadow: 0px 3px 3px 0 rgba(0,0,0,0.2)
        .sidebarContent
            display: block
    .vehicles-list
        margin-bottom: 15px