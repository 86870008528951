.vehicle-list-wrapper,
.devices-tree,
.vehicle-data
	.status-row
		display: flex
		align-items: center
		margin-bottom: 8px
		&.non-recording
			&.full-access:last-child
				position: relative
				top: 28px
		.icon
			width: 8px
			height: 8px                    
			margin-right: 6px                    
			border-radius: 50%
			&.driving
				background: #0FA739
			&.parked
				background: #2E318C
			&.awake
				background: #29A9E0
			&.offline
				border: 1px solid #000
			&.disabled
				background: #C2C2C2
			&.sleep
				background: url(../../../../../public/images/ico/sleep_mode.png) no-repeat center center
				background-size: contain
				width: 16px
				height: 16px
				border-radius: initial
				& ~ .title
					min-width: initial
					width: 51px !important
				// width: 9px
				// height: 9px
				// box-shadow: #000 3px 2px 0px 0px
				// transform: rotate(133deg) translate(-5px, -2px)
				// margin-right: 5px
			&.pending_awake
				background: #8C36E0
			&.pending_sleep
				background: #333333
		.title
			min-width: 60px
			margin-right: 10px
			text-transform: capitalize
			cursor: default

.devices-tree
	.category-item
		box-shadow: none
		border: 1px solid #232323
		margin: 5px 0
		svg
			fill: #000
			min-width: 16px
		&.expanded
			border-color: var(--theme-colors-primary)
			svg
				transform: rotate(90deg)
				fill: var(--theme-colors-primary)
	.status-row	
		margin: 0
		.icon
			margin-right: 2px
		span
			margin-right: 15px
			font-size: 12px
	.group-statuses
		margin-left: 8px

@keyframes pending
	0%
		opacity: 100%
	75%
		opacity: 0%
	100%
		opacity: 100%