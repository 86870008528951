#map {
    height: 100%;
}

.map-narker {
    cursor: pointer;
    touch-action: none;
    z-index: 100000;
}

.map-narker>img {
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0;
    max-width: none;
    position: absolute;
    transform: translate(-50%, -100%);
}

.map-price-container {
    opacity: 0.8;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    z-index: 100001;
    display: block;
    position: absolute;
    transform: translate(-15%, -165%);
}

.map-price-marker {
    position: relative;
    /*width: 70px;*/
    height: 21px;
    border-radius: 3px;
    line-height: 22px;
    background-color: #46b13c;
    /*background-color: #52ca46;*/
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
    text-align: center;
    color: #fff;
    font-size: 11px;
    font-family: MuseoSans;
    font-weight: 700;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    border-color: #3e9435;
    z-index: 1;
    padding: 0 3px;
}

.markerImg {
    width: 25px;
    height: 25px;
    user-select: none;
    border: 0px;
    padding: 0px;
    margin: 0px;
    max-width: none;
}

.map-price-marker:before,
.map-price-marker:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    /*border-style: solid;*/
    display: block;
}

.map-price-marker:after {
    left: 31px;
    top: 19px;
    border-width: 5px;
    border-color: #52ca46 transparent transparent;
}

.map-price-marker:before {
    left: 30px;
    top: 20px;
    border-width: 6px;
    border-color: #3e9435 transparent transparent;
}

.map-price-marker.visited {
    background-color: #fff;
    color: #7b8d93;
    border-color: #7b8d93;
}

.map-price-marker.visited:after {
    border-color: #fff transparent transparent;
}

.map-price-marker.visited:before {
    border-color: #7b8d93 transparent transparent;
}

.map-price-marker.visited.active,
.map-price-marker.visited:hover {
    background-color: #7b8d93;
    color: #fff;
    border-color: #5b6a6f;
}

.map-price-marker.visited.active:after,
.map-price-marker.visited:hover:after {
    border-color: #7b8d93 transparent transparent;
}

.map-price-marker.visited.active:before,
.map-price-marker.visited:hover:before {
    border-color: #5b6a6f transparent transparent;
}

.map-price-marker.active,
.map-price-marker:hover {
    background-color: #3e9435;
    color: #fff;
    border-color: #3e9435;
    z-index: 3;
}

.map-price-marker.active:after,
.map-price-marker:hover:after {
    border-color: #3e9435 transparent transparent;
}

.map-price-marker.active:before,
.map-price-marker:hover:before {
    border-color: #3e9435 transparent transparent;
}

.searchContainer {
    .dropdown {
        position: absolute;
        z-index: 99999;
        list-style-type: none;
        width: 360px;
        border: rgb(15, 22, 33);
        list-style: none;
        left: 317px;
        top: 57px;
    }

    input {
        z-index: 9999;
        font-size: 18px;
        font-family: 'Allerta', Helvetica, Arial, sans-serif;
        color: #495057;
        position: absolute;
        top: 17px;
        left: 300px;
        width: 360px;
        height: 35px;
        padding: 5px;
        margin-left: 17px;
        margin-top: 7px;
        border: none;
    }

    ul {
        list-style: none;
        background-color: #fff;
        padding: 0;
        margin: 0;
        width: 360px;
    }

    li {
        list-style-type: none;
        height: 15px;
        padding: 5px 10px 20px 10px;
        box-shadow: rgb(158, 202, 237) 0px 0px 4px;
        background-color: #fff;
        display: list-item;
        text-align: -webkit-match-parent;
        font-family: 'Allerta', Helvetica, Arial, sans-serif;
        color: #495057;
    }

    li:hover {
        background-color: yellowgreen;
        cursor: pointer;
    }

    #list {
        cursor: pointer;
    }

    .fa-search-custom {
        position: absolute;
        left: 645px;
        top: 35px;
        z-index: 99999;
    }
}