.vehicleSidebarTable
	padding: 0 10px
	.event-wrapper
		.event-row
			display: flex
			justify-content: space-between
			align-items: center
			div
				font-size: 14px
				word-break: break-all
				line-height: 34px
			.timestamp
				width: 20%
			.event-type
				width: 55%
			.video-option
				width: 25%
				text-align: right
		.event-snapshot
			width: 90%
			margin: 0 auto
			img
				width: 100%
				height: auto