//FILTER TOGGLE
.dropdown-toggle.event-filter-toggle, .event-filter-toggle
    background: none
    border: none
    box-shadow: none
    padding: 0px
    line-height: 0
    color: #333
    &:hover, &:focus, &:active, &:active:focus
        color: #333
        background: none
        border: none
        box-shadow: none
    .bi-filter
        height: 2.25rem
        width: 2.25rem
    .filter-dot
        height: 2.25rem
        width: 2.25rem
        color: var(--theme-colors-secondary)
        position: relative
        top: -12px
        right: 20px
//FILTER MENU
.event-filter-menu
    min-width: 260px
    max-width: 260px
    padding: 4px 0px
    .category-subtitle
        font-size: 0.875rem
        text-align: center
        margin-bottom: 0.5em
        padding-bottom: 0.125em
    .button-link
        display: block
        margin: auto
    .filters-list
        padding: 0px 4px
        margin: 0px
        list-style-type: none
        li 
            display: block
            width: 100%
            margin-bottom: 4px
        &.two-columns li
            display: inline-block
            width: calc(50% - 2px)
            &:nth-child(even)
                margin-left: 4px
        .filter-button
            border: 1px solid #D9D9D9
            border-radius: 5px
            padding: 2px 4px
            background: #fff
            font-size: 0.875rem
            font-weight: 100
            color: #666666
            display: flex
            width: 100%
            text-align: left
            justify-content: space-between
            align-items: center
            transition-property: color, background-color, border-color
            transition-duration: 0.3s
            line-height: 1
            .filter-check
                width: 0.875rem
                height: 0.875rem
                color: var(--theme-colors-tertiary)
                flex-shrink: 0
            &:hover
                background-color: #f5f5f5
                border-color: var(--theme-colors-primary)
                color: var(--theme-colors-primary)
            .flagSeleted
                top: -1px
                margin-right: 5px
                color: var(--theme-colors-tertiary)
            .groupName
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
                display: block
    .closeBtn
        width: 100%
        padding: 0px
        &:hover
            background-color: #f0f0f0
        svg
            height: 1.25rem
            width: 1.25rem

// VEHICLE SIDEBAR FILTER
.vehicleSidebarTable
    .typeFilterHeader
        overflow: inherit
        .event-filter-wrapper
            display: inline-flex
            align-items: center
            position: relative
            svg
                height: 1.5rem
                width: 1.5rem
            
        .event-filter-toggle
            margin-left: 3px
            .filter-dot
                position: absolute
                right: -0.75rem
                top: -0.625rem
        .event-filter-menu
            min-width: 210px

// DACHBOARD FILTER
.typeFilterMenu
    overflow-y: scroll
    max-height: 528px
    .dropdown-menu
        min-width: 13rem
    .category-subtitle
        text-align: center
    .btn-select-all
        display: block
        margin: 0 auto 4px
        padding: 0
    ul
        padding: 0
        li
            align-items: center
            border: 1px solid #d9d9d9
            border-radius: 5px
            color: #666
            cursor: pointer
            display: flex
            font-size: 14px
            justify-content: space-between
            margin: 0 8px 3px
            padding-left: 5px
            transition-duration: .3s
            transition-property: color,background-color,border-color
            .name
                text-overflow: ellipsis
                overflow: hidden
                width: 112px
                white-space: nowrap
                text-transform: capitalize
            .flagSelected
                color: var(--theme-colors-tertiary)
                height: auto
                margin-right: 5px
                top: -1px
                width: auto