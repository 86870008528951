.trip-snapshots
    width: 100%
    background-color: #fff
    position: absolute
    bottom: 0
    z-index: 10000
    .no-snapshots
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        font-size: 20px
    .trip-snapshots-toggle
        height: 24px
        border: 1px solid #ccc
        color: var(--theme-colors-primary)
        // font-size: 20px
        text-align: center
    .images-wrapper
        height: 212px
        // display: flex
        // text-align: center
        // justify-content: space-around
        // margin-top: 30px
        margin-left: 30px
        margin-right: 30px
        .image-block
            text-align: center
            &.hover
                img
                    box-shadow: 0px 1px 4px 0px var(--theme-colors-tertiary)
                    border: 3px solid var(--theme-colors-tertiary)
                    width: 220px
            &.active
                img
                    box-shadow: 0px 1px 4px 0px var(--theme-colors-tertiary)
                    border: 3px solid var(--theme-colors-tertiary)
                    width: 300px
            img
                width: 200px
                display: initial
                cursor: pointer
                border-radius: 4px
            .time-wrapper
                margin-top: 4px
                .glyphicon-alert
                    color: #F28131
                    margin-left: 5px
                    font-size: 18px
                    vertical-align: text-bottom
        .slick-slider
            height: 100%
            display: flex
            flex-direction: column
            justify-content: center
            .slick-arrow
                color: var(--theme-colors-primary)
                font-size: 20px
                &.slick-disabled
                    display: none
            .slick-prev:before, .slick-next:before
                content: none
                