.tripsGridWrapper
	.separator
		width: 95%
		height: 2px
		background-color: var(--theme-colors-primary)
		margin: 0 auto
	.tripCardsWrapper
		height: calc(100vh - 510px)
		overflow: hidden auto
		.tripCard
			height: 115px
			background-color: #F3F3F3
			margin-top: 5px
			border-left: 5px solid var(--theme-colors-primary)
			position: relative
			padding-left: 8px
			// cursor: pointer
			&:hover,
			&.active
				background-color: #9BA9C6
				.tripLine
					background-color: #606776
				.tripLine
					&:before, &:after
						background-color: #606776
			.iconWrapper
				background: rgb(255, 255, 255)
				border-radius: 20px
				top: 5px
				position: absolute
				width: 24px
				height: 24px
				text-align: center
				.glyphicon
					color: var(--theme-colors-primary)
					font-size: 17px
					vertical-align: middle
			.tripEnd
				height: 30px
				display: flex
				padding-top: 10px
				img
					width: 75%
			.tripStart
				position: relative
				display: flex
				padding-top: 5px
				img
					width: 75%
			.tripDuration
				height: 36px
				position: relative
				.content
					padding-left: 27px
					height: 100%
					display: flex
					align-items: center
					padding-top: 5px
					font-weight: 100
			.address
				padding-left: 27px
				font-size: 13px
				line-height: 13px
				margin-right: 5px
				max-width: 300px
				font-weight: 600
			.timestamp
				font-size: 12px
				line-height: 12px
			.tripLine
				top: 0
				height: 100%
				position: absolute
				left: 11px
				width: 2px
				background-color: #9BA9BF
				&:before
					transform: rotate(30deg)
					content: ""
					position: absolute
					width: 2px
					height: 12px
					background-color: #9BA9BF
					top: 0
					left: -3px
				&:after
					transform: rotate(145deg)
					content: ""
					position: absolute
					width: 2px
					height: 12px
					background-color: #9BA9BF
					right: -3px
					top: 0
			.alertsWrapper
				display: flex
				bottom: 1px
				position: absolute
				right: 0
				.alertDropdown 
					margin-right: 8px
					.alertDropdownToggle
						background: transparent
						border: none
						box-shadow: none
						padding: 0
					.alertDropdownMenu
						min-width: 230px
						padding: 10px
						max-height: 200px
						overflow: auto
						li
							margin-bottom: 3px
							a
								color: var(--theme-colors-primary)
								padding: 0
								outline: none
							&:last-child
								margin-bottom: 0
				.alertBlock
					padding: 2px 0 0 4px
					border-radius: 4px
					font-weight: 600
					font-size: 14px
					color: #2A2A2A
					background-color: #fff
					.glyphicon
						font-size: 17px
					.glyphicon-alert
						color: #F28131
						font-size: 22px
						vertical-align: text-bottom
					.glyphicon-dashboard
						color: #EC0021
					.value
						margin: 0 8px 0 4px
			.prevTrip
				position: absolute
				bottom: -15px
				z-index: 1
				width: 100%
				display: flex
				justify-content: center
				.value
					background: #fff
					padding: 3px 5px
					border-radius: 4px
					color: #609CCB
					font-weight: 600
	.no-trips-message
		margin: 10px 20px
		font-size: 18px
		text-align: center