.adas-calibration
	&.disabled
		.box-toggle
			cursor: not-allowed
			.react-toggle
				opacity: 0.5
				pointer-events: none
		input
			opacity: 0.5
			pointer-events: none
	.toggles-container
		display: flex
		align-items: center
		justify-content: space-between
	.input-wrapper
		display: flex
		justify-content: space-between
		align-items: center
		flex-wrap: wrap
		&.justify-evenly
			justify-content: space-evenly
		.settings-title
			width: 165px
	label
		margin-bottom: 0
	.has-pending-config
		color: #ff9900