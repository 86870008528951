.markerAlertPopup
	.imgWrapper
		width: 300px
		cursor: pointer
		// height: 105px
		&.no-snapshot
			min-height: 169px
			background: #888
			color: #fff
			text-align: center
			font-size: 16px
			position: relative
			.snapshot-error-message
				position: absolute
				top: calc(50% - 16px)
				width: 100%
				left: 0px
		img
			width: 100%
	.controlsWrapper
		padding: 5px 10px 10px 10px
		.eventType
			font-weight: bold
			font-size: 16px
			margin-right: 5px
		.eventTime
			font-size: 14px
		.controls
			display: flex
			justify-content: space-between
			align-items: center
			.channelsList
				padding: 0
				margin: 0
				.list-inline-item
					font-weight: bold
					font-size: 14px
					background: #434343
					border: 1px solid #434343
					color: #fff
					display: inline-block
					&.active
						background: #00AFF2
						a
							cursor: auto
					&:first-child
						border-radius: 6px 0 0 6px
					&:last-child
						border-radius: 0 6px 6px 0
					a
						color: #fff
						padding: 2px 15px
						display: block
						cursor: pointer
						&:hover,
						&:focus
							text-decoration: none
			.controlButtons
				.button
					background-size: 30px 30px
					width: 28px
					height: 28px
					float: left
					margin-left: 2px
					padding: 0
					background-color: var(--theme-colors-primary)
					background-position: center center
					background-repeat: no-repeat
					-webkit-border-radius: 4
					-moz-border-radius: 4
					border-radius: 4px
					display: block
					border: solid #919191 1px
					cursor: pointer
				.playButton
					background-image: url(../../../../../../public/images/ico/event_play.png)
				.snapshotButton
					background-image: url(../../../../../../public/images/ico/event_snapshot.png)
				.shareButton
					background-image: url(../../../../../../public/images/ico/event_share.png)
				.flaggedButton
					background-image: url(../../../../../../public/images/ico/event_flagged_off.png)
					&.on
						background-image: url(../../../../../../public/images/ico/event_flagged_on.png)
				.archivedButton
					background-image: url('../../../../../../public/images/ico/event_archived.png')
					&.restoring
						background-color: var(--theme-colors-secondary) 

.alertPopupBubble
	top: -56px !important
	.H_ib_body
		transform: translate(50%, 0%)
		margin-right: 0
		padding-top: 38px
		.H_ib_content
			padding: 0
			margin: 0