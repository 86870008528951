.custom-overlay-trigger
	&.warn
		color: white
		.popover-header
			background-color: #FB9E14
		.close-btn
			background-color: transparent
			color: white
	&.crit
		color: white
		.popover-header
			background-color: #E83219
		.close-btn
			background-color: transparent
			color: white
	&.ackn
		color: white
		.popover-header
			background-color: #666
		.close-btn
			background-color: transparent
			color: white
	.close-btn
		border: none
		float: right
		font-size: 30px
		line-height: 15px
		padding: 0
		margin: 0
		font-weight: 300
	

