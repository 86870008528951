.rosco-modal
    .modal-dialog.dialog-camera-status
        .modal-content
            .modal-header
                .modal-title
                    .header-question svg
                        width: 24px
                        height: 26px
                        position: relative
                        top: 4px
                        left: 9px
            .modal-body
                .statusInfoTopWrapper
                    font-size: 18px
                .statusInfoWrapper
                    margin-top: 15px
                    margin-bottom: 15px
                    height: 300px
                    .channelSnapshot
                        height: 100%
                        display: block
                        margin: 0 auto
                    .channelNoImage
                        width: 500px
                        height: 100%
                        margin: auto
                        background: #ddd
                        justify-content: center
                        align-items: center
                        display: flex
                        color: red
                    @media (max-width: 767px)
                        height: auto
                        .channelSnapshot
                            width: 98%
.custom-request-modal
    font-size: 14px
    line-height: 1.42857143
    color: #333
    // max-width: 600px
    height: auto
    label
        font-weight: 700
    .rowFlex
        display: flex
        flex-direction: row
        align-items: flex-start
        .columnFlex
            display: flex
            flex-direction: column
        .search-autocomplete
            width: 282px
    .modal-body
        padding: 10px 15px 15px 15px
    .modal-footer
        .rosco-primary:hover
            color: #fff
        .rosco-primary-cancel
            padding: 6px 12px
            border: 2px solid black
            margin-right: 10px
        .rosco-primary-cancel:hover
            background-color: #e5e5e5
        .btn
            width: 130px !important
            height: 35px !important
    .select-box
        display: flex
        flex-direction: column
        position: relative
        .vehicle-search
            background: white
            padding-left: 10px
            background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat right
            background-position-x: calc(100% - 20px)
            cursor: pointer
        .text-label
            font-size: 12px
            position: absolute
            color: #6c6c6c
            top: -10px
            left: 10px
    .row
        padding-top: 15px
        .vehicle-group
            max-width: 405px
            p
                padding-top: 10px
        .rowFlex-date
            display: flex
            flex-direction: row
            align-items: flex-start
        @media (max-width: 430px)
            .rowFlex-date
                flex-direction: column
                .select-box
                    margin-top: 20px !important
        .date-group
            .request-timestamp
                margin-right: 68px
                input
                    color: white
                    background-color: var(--theme-colors-primary)
                    max-width: 150px
                    height: 38px
                    border: none
                input:hover
                    background-color: #00436d
            .rosco-select
                padding-left: 6px
                min-width: 150px
                -webkit-appearance: none
                -moz-appearance: none
                appearance: none
                background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat right
                background-position-x: calc(100% - 20px)
            .select-box
                margin-top: 5px
        .block-info
            padding-top: 10px
            .textNote
                color: var(--theme-colors-secondary)
                padding: 5px
                text-align: center
        .columnFlex
            .visualizer
                height: 230px
                margin-top: -20px
                overflow: scroll
                overflow-y: hidden
                position: relative
                .rowFlex-visualizer
                    display: flex
                    flex-direction: row
                    position: absolute
                    height: 150px
                    width: 100%
                    .box-before
                        margin-left: calc(50% - 60px)                        
                        transform: rotate(180deg)
                        svg
                            top: calc(50% - 14px)
                            position: absolute
                            left: 25px
                            cursor: pointer
                    .box-after
                        padding-left: calc(50% - 60px)
                        svg
                            top: calc(50% - 18px)
                            position: absolute
                            margin-left: calc(-50% + 87px)
                            cursor: pointer
                    .box-vertical-line
                        position: relative
                        border-right: 1px solid black
                        height: 100%
                        label
                            position: absolute
                            min-width: 60px
                            height: 20px
                    .box-center-vertical-line
                        border-right: 1px solid black
                        float: center
                        height: 100%
                        label
                            position: absolute
                            min-width: 60px
                            height: 20px
                    .box_1
                        border-left: 1px solid black
                        height: 100%
                .rectangle
                    border: 3px solid black
                    background-color: rgba(10, 10, 10, 0.5)
                    height: 25px
                    width: 120px
                    position: sticky
                    z-index: 999
                    top: 85px
                    left: 50%
                    transform: translateX(-50%)
            .rowFlex-inputs
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                padding-top: 10px
                .start-date, .end-date
                    width: 90px
                    padding-bottom: 8px
                    border: none
                    border-bottom: 1px solid black
                    outline: none
                    margin: 15px 25px 5px 25px
                    text-align: center
                label
                    margin-bottom: 0px
                    font-size: 18px
                    font-weight: 400
        .circle-green
            width: 10px
            height: 10px
            border-radius: 50%
            margin: 4px 4px 0px 12px
            background: #16be16
        .circle-blue
            width: 10px
            height: 10px
            border-radius: 50%
            margin: 4px 4px 0px 12px
            background: var(--theme-colors-alternate)
    @media (max-width: 430px)
        .modal-footer
            display: flex
            justify-content: center
    .has-error .help-block
        margin: 0 !important
@media (max-width: 768px)
    .custom-request-modal
        max-width: 100%
.notifications-modal
    .rosco-select
        &.disabled
            pointer-events: none
            opacity: 0.5
.addInstallReportModal
    width: 425px
    .modal-title
        font-weight: 400 !important
    .modal-body
        .formReportInstall
            display: flex
            flex-direction: column
            overflow: hidden
            font-size: 15px
            label
                font-weight: 700
            .itemRow
                display: flex
                flex-direction: row
                justify-content: space-between
                align-items: center
                padding-bottom: 20px
                .btn-view-image
                    color: blue
                    text-decoration: none
                    cursor: pointer
                    margin-right: 5px
                .btn-delete-image
                    color: red
                    text-decoration: none
                    cursor: pointer
                    margin-left: 5px
            .itemRow-checkbox label
                padding-left: 10px
            .upload-input
                display: none
            hr
                width: 100%
                margin: 0
            .custom-button
                padding: 0
                color: black
                border: 1px solid var(--theme-colors-primary)
                background-color: white
                min-width: auto
                &:focus, &:hover
                    background-color: #e6e6e6
                &:active, &:active:focus
                    background-color: #ccc
                .lbl
                    font-weight: normal
                    margin: 0
                    cursor: pointer
                    padding: 7px
            .itemRow-checkbox
                padding-bottom: 6px
            input
                border: 1px solid grey
                padding: 5px
            input:focus
                outline: none
                border-color: var(--theme-colors-primary)
            .lbl-certified
                padding-top: 10px
            .firstNameInput
                width: 50%
                margin-right: 10px
            .lastNameInput
                width: 50%
                margin-left: 10px
            .itemInput
                padding-bottom: 20px
            .fullWidthInput
                width: 100%
            .load-image
                width: 100px
            .item-right
                width: 120px
                text-align: right
            .upload-files
                padding-bottom: 12px
                .itemRow
                    padding-bottom: 12px
    .modal-footer
        padding-top: 0px
@media (max-width: 425px)
    .addInstallReportModal
        width: 90%
.modalEventLabel
    margin: 10rem auto
    .modal-dialog
        .modal-content
            .modal-header
                background: #0071b6
                color: #fff
                padding: 0.2rem 1rem
                .modal-title
                    font-size: 17px
            .modal-body
                div
                    .form-group
                        margin-bottom: 30px
                        .control-label
                            margin-bottom: 0px
                            font-size: 14px
                            font-weight: 400
                            color: #C0C0C0