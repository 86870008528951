.gm-style
	.gm-style-iw
		overflow: hidden !important
		padding: 0
		.gm-style-iw-d
			overflow: hidden !important
		.gm-ui-hover-effect
			img
				margin: 11px 0 !important
				width: 19px !important
				height: 19px !important
	.gm-style-iw-t
		bottom: 66px !important
	.btn-traffic
		// font-family: Roboto, Arial, sans-serif
		font-family: var(--theme-font)
		height: 40px
		background-color: rgb(255, 255, 255)
		color: rgb(86, 86, 86)
		min-width: 66px
		font-size: 18px
		margin-top: 10px
		border: 0
		box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.33)
		&.active
			color: rgb(0, 0, 0)
			font-weight: 500

.infoTripBubble
	top: -56px !important
	.H_ib_body
		transform: translate(50%, 0%)
		margin-right: 0
		padding-top: 48px
		min-width: 90px
		max-width: 300px
		.H_ib_content
			padding: 0
			margin: 0
		.H_ib_close
			position: absolute
			width: 15px
			height: 15px
			top: 15px
			right: 15px
.markerHistoryPopup
	.headerWrapper
		.header
			padding: 20px 0 10px 0
	.speed-limit-wrapper
		border: 2px solid
		border-radius: 5px
		background: #fff
		color: #333
		padding: 5px
		text-align: center
		margin-right: 8px
		.speed-limit-label
			font-weight: 500
			&.speed
				margin-top: 0px
				font-size: 24px
	.speed-unit-label
		font-size: 16px
		font-weight: 500
		margin-bottom: 0px
.markerInfoPopup
	max-width: 380px
	.actionsTitle
		color: #fff
		padding-top: 1px
		font-weight: 600
		text-align: center
	.headerWrapper
		.title
			font-size: 16px
			font-weight: 600
			margin: 5px 20px
			min-height: 20px
		.header
			background-color: var(--theme-colors-primary)
			.menu
				display: flex
				flex-direction: row
				.menuItem
					background: transparent center center no-repeat
					background-size: 35px
					min-width: 48px
					height: 35px
					&.speed
						background-image: url(../../../../../../public/images/speedometer.png)
					&.direction
						background-image: url(../../../../../../public/images/compass.png)
					&.parking
						background-image: url(../../../../../../public/images/carkey.png)
	&.markerHistoryPopup .headerWrapper .header
		padding: 5px 0px 0px
		.menu .menuItem
			background-size: 30px
		.addressWrapper
			background-color: #fff
			padding-top: 10px
			.btn-address
				border: none
				box-shadow: none
				background-color: transparent
			.link-request
				outline-width: 0
	.addressWrapper
		.btn-address
			color: var(--theme-colors-primary)
			background-size: 35px
			border: none
			width: 40px
			height: 40px
			outline: none
			padding: 0px
			&:hover, &:active, &:focus
				color: var(--theme-colors-tertiary)
				background-color: transparent
				box-shadow: none
		.reportTime
			font-size: 14px
			font-weight: 400
			margin-bottom: 7px
			text-align: center

.tripSpeedBubble
	z-index: 10000000

@media (max-width: 767px)
	.markerInfoPopup .headerWrapper .header .menu
		flex-direction: column
