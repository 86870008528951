.filters-wrapper
	grid-area: filter
	margin-top: -10px
	.typeFilterMenu
		min-width: 210px
		padding: 4px 0px 2px
		li
			border: 1px solid #D9D9D9
			border-radius: 5px
			margin: 0 8px 3px 8px
			padding-left: 5px
			cursor: pointer
			color: #666666
			display: flex
			justify-content: space-between
			align-items: center
			transition-property: color, background-color, border-color
			transition-duration: 0.3s
			&:hover
				background-color: #f5f5f5
				border-color: var(--theme-colors-primary)
				color: var(--theme-colors-primary)
			.flagSeleted
				top: -1px
				margin-right: 5px
				color: var(--theme-colors-tertiary)
			.groupName
				text-overflow: ellipsis
				overflow: hidden
				white-space: nowrap
				display: block