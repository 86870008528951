.cloud-storage
    .event-search
        display: inline-flex
        align-items: center
        .event-search-select
            width: 83px
            height: 35px
            background-color: var(--theme-colors-primary)
            color: #fff
            border-radius: 5px 0px 0px 5px
            padding: 7px
            margin-right: -3px
            z-index: 2
            border: none
            font-size: 0.875rem
            &:focus-visible
                outline: none
            option
                background-color: #fff
                color: #333
        .event-search-input
            position: relative
            flex-grow: 1
            z-index: 1
            margin-bottom: 0px
            width: 100%
            height: 35px
            &.md-width
                width: 200px
            input
                width: inherit
                height: 35px
                font-size: 0.875rem
                line-height: 1.4
    .view-format-select
        margin-left: 10px
        .custom-button
            margin: 0px
            &:hover, &.active
                background-color: var(--theme-colors-primary)
                border-color: var(--theme-colors-primary)
                color: #fff
            &:first-child
                border-radius: 5px 0px 0px 5px
            &:last-child
                border-radius: 0px 5px 5px 0px

    .react-bs-table-container .react-bs-container-body tr td
        font-size: 14px
        padding-right: 4px
        padding-left: 4px
        word-break: break-all
    .btn-full-100, .btn-full-100:hover
        margin: 0
        width: 100%
    .imageButton
        background-size: 26px 26px
        width: 26px
        height: 26px
        min-width: 26px
        min-height: 26px
        padding: 0
        background-color: var(--theme-colors-tertiary) 
        background-position: center center
        background-repeat: no-repeat
        -webkit-border-radius: 4
        -moz-border-radius: 4
        border-radius: 4px
        display: inline-block
        cursor: pointer
        vertical-align: middle
    .imageButton:disabled, .imageButton[disabled]
        display: none
    .playButton
        background-image: url('../../../../../../public/images/ico/event_play.png')
    .playAllButton
        background-image: url('../../../../../../public/images/ico/event_play_all.png')
    .snapshotButton
        background-image: url('../../../../../../public/images/ico/event_snapshot.png')
    .downloadButton
        background-image: url('../../../../../../public/images/ico/event_download.png')
    .requestVideoButton
        background-image: url('../../../../../../public/images/ico/request_video.png')
    .deleteButton
        color: #ff0000
        background: #fff
    .requestButton
        background-image: url('../../../../../../public/images/ico/event_unzip.png')
    .shareButton
        background-image: url('../../../../../../public/images/ico/event_share.png')
    .floodRestoreButton
        background-image: url('../../../../../../public/images/ico/flood_restore.png')
    .flaggedButton
        background-image: url('../../../../../../public/images/ico/event_flagged_off.png')
        &.on
            background-image: url('../../../../../../public/images/ico/event_flagged_on.png')
    .archivedButton
        background-image: url('../../../../../../public/images/ico/event_archived.png')

    .event-subheader-grid
        display: grid
        grid-template-areas: "date search filter foot format"
        grid-template-columns: auto auto 1fr auto auto
        align-items: center
        .event-date
            display: flex
            align-items: center
            margin-left: -3px
            #date-select-dropdown
                div
                    button
                        height: 35px
            .dropdown-menu-right
                right: auto
            .date-dropdown
                margin-left: 0px
                margin-right: 0px
            .rdt
                top: 1px
                display: inline-block
                input
                    text-align: center
                    background-color: #fff
                    cursor: pointer
                    width: 95px
            svg
                vertical-align: middle
                cursor: pointer
            .name
                display: inline-block
                text-align: left
                .input
                    display: inline-block
        .view-format-select
            grid-area: format
        .event-search
            grid-area: search
        .event-filters
            grid-area: filter
        .event-buttons
            grid-area: foot
            text-align: center
            .custom-button:first-child
                margin-right: 10px
        @media (max-width: 1274px)
            gap: 0.625em 0em
            grid-template-areas: "date search filter format" "foot foot foot foot"
            grid-template-columns: auto auto 1fr auto
            .event-buttons
                display: flex 
                .custom-button
                    flex-basis: 50%
                & + .custom-button
                    margin-right: 10px
        @media (min-width: 768px)
            .events-search
                width: 300px
        @media (max-width: 767px)
            grid-template-areas: "date search search filter" "foot foot format format"
            grid-template-columns: auto 1fr auto auto
        @media (max-width: 460px)
            grid-template-areas: "date date format format" "search search search filter" "foot foot foot foot"
            grid-template-columns: auto 1fr auto auto

    .flagged-events-subheader
        display: flex
        justify-content: space-between
        .event-search
            width: 50%
            @media (max-width: 767px)
                flex-grow: 1
    
    .icon.play-button
        &:hover:not([disabled])
            color: var(--theme-colors-primary)

.event-grid
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 1em
    max-width: 2000px
    .square-card
        background-color: #F0F0F0
        &:hover .event-actions
            opacity: 1
        .card-content
            position: relative
            &.no-image .event-actions
                background-image: none
        .card-snapshot
            width: 100%
            cursor: pointer
        .card-text
            padding: 5px 8px
            font-size: 0.9375rem
            &.clickable-footer
                cursor: pointer
            &.active
                background-color: var(--theme-colors-tertiary)52
            .vehicle-name
                font-weight: 500
                font-size: 1rem
                color: #333
            .event-ts
                font-weight: 100
            .type-label
                display: block
                text-align: right
                background-color: #fff
                font-weight: 500
                border-radius: 3px
                padding: 0px 3px
                & + .type-label
                    margin-top: 2px
            .event-type
                display: inline-block
                border: 1px solid #919191
                border-radius: 3px
                padding: 0px 2px
                font-weight: 600
                float: right
        .drop-down
            text-align: center
            font-weight: bold
        .event-actions
            display: flex
            justify-content: center
            padding: 5px 0px
            bottom: 0px
            cursor: default
            position: absolute
            width: 100%
            background-image: linear-gradient(transparent, #000)
            opacity: 0
            color: #fff
            transition: opacity 0.2s
            .imageButton
                height: 35px
                width: 35px
                border: none
            .note-button
                height: 35px
            svg
                height: 35px
                margin-top: 0px
        .icon
            svg
                height: 1.5rem
                width: 1.5rem
            &.flagged-button
                color: #fff
            &.inverted
                border: none
                svg
                    height: 2.25rem
                    width: 2.25rem
        .play-button
            position: absolute
            top: calc(50% - 32px)
            width: 100%
            left: 0px
            color: #ccc
            display: block
            opacity: 0
            transition: opacity 0.2s
            svg
                width: 5em
                height: 5em
        &:hover, &:hover .image-error
            .play-button
                color: #fff !important
                cursor: pointer
                opacity: 1
        .image-error
            background-color: #888
            color: #ccc
            text-align: center
            padding-top: 56%
            font-size: 20px
            &.video-error
                cursor: default
                color: #fff
                .video-error-message
                    position: absolute
                    top: calc(50% - 14px)
                    width: 100%
                    left: 0px
            .play-button
                color: #ccc
                opacity: 1
                top: calc(50% - 45px)
                transition: color 0.2s
    @media (max-width: 1200px)
        grid-template-columns: repeat(3, 1fr)
    @media (max-width: 992px)
        grid-template-columns: 1fr 1fr
    @media (max-width: 767px)
        margin: 0px -15px
        display: block
        .square-card
            & + .square-card
                margin-top: 10px
            .play-button, .image-error .play-button
                opacity: 1
                color: #fff
            .event-actions
                opacity: 1
            .card-text
                padding: 8px 10px
            .card-snapshot
                min-height: 50px

.grid-pagination
    list-style: none
    padding-left: 10px
    margin-top: 25px
    li
        margin: 0px 5px
        display: inline-block
        a
            color: #888
            outline: none
            padding: 8px
            border-radius: 5px
            font-weight: bold
            font-size: 16px
            &:hover
                color: var(--theme-colors-primary)
                text-decoration: none
    .active-pagination
        a
            background-color: #fff
            color: var(--theme-colors-primary)
    .disabled-pagination
        display: none

.popover
    max-width: 450px
    width: 350px
    textarea
        width: 100%
        height: 60px
        margin-bottom: 10px

.event-actions
    display: flex
    flex-wrap: wrap
    gap: 2px
    align-items: center
    button, svg
        width: auto
        height: 26px
    svg
        margin-top: -1px