.label-tag
    color: white
    padding: 3px
    border-radius: 3px
    background-color: #666
    &.internal
        background-color: var(--theme-colors-secondary)
    &.beta
        background-color: var(--theme-colors-primary)

    