.event-feedback-button.active
    svg
        fill: #E83219
.feedbacks-wrapper
    margin-bottom: 1rem
    .feedback-option
        margin-bottom: 5px
.additional-details div
    margin-bottom: 5px

.event-feedback-thankyou
    .modal-content .modal-body .modal-text
        font-size: 1rem

